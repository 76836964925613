import {TranslateService} from '../_services/translate.service';
import {map, mergeMap, of, tap} from 'rxjs';
import {getBrowserLocales} from '@app/shared/_helpers/i18n';
import {availableLanguages, fallBackLanguage} from "@config/config";
import {User} from "../_interfaces/user";
import {MockService} from "../_services/mock.service";
import {EmailchefService} from "@app/shared/_services/emailchef.service";

export function setupUserConfig(
  translateService: TranslateService,
  emailchefService: EmailchefService,
  mockService: MockService
): Function {
  return () => {
    return new Promise((resolve, reject) => {

      const supportedLanguages = availableLanguages.map(language => language.id);
      let navigatorLanguage = getBrowserLocales({languageCodeOnly: true})[0] || fallBackLanguage;

      if (!~supportedLanguages.indexOf(navigatorLanguage)) {
        navigatorLanguage = fallBackLanguage;
      }

      return of<User.Config>({
        config: {
          fallback_language: fallBackLanguage,
          supported_languages: supportedLanguages,
          current_language: navigatorLanguage,
          timezone: '+00:00'
        },
        translations: {
          [fallBackLanguage]: mockService.getData('i18n/en'),
          ...(navigatorLanguage !== fallBackLanguage) && {[navigatorLanguage]: mockService.getData('i18n/' + navigatorLanguage)}
        }
      }).pipe(
        tap(appConfig => translateService.reloadByConfig(appConfig)),
        mergeMap(
          config => emailchefService.getAccountInfo()
            .pipe(
              map<any, User.Config>(res => {
                if (res.data.lang) {
                  config = {
                    config: {
                      ...config.config,
                      current_language: res.data.lang
                    },
                    translations: {
                      ...config.translations,
                      [res.data.lang]: mockService.getData(`i18n/${res.data.lang}`)
                    }
                  }
                }
                return config
              })
            )
        )
      )
        .subscribe({
          next: appConfig => {
            translateService.reloadByConfig(appConfig);
            resolve(appConfig);
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  };
}
