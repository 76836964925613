import {inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Auth} from '../_models/auth';
import {environment} from "@environments/environment";
import {DOCUMENT} from "@angular/common";

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  private document = inject(DOCUMENT);

  private currentUserSubject = new BehaviorSubject<Auth>({
    Authkey: this.getAuthkey()
  });

  public currentUser = this.currentUserSubject.asObservable();


  getCookieValue(cookieName: string): string {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(this.document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }

      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }

    return '';
  }


  public getAuthkey() {

    const localStorageValue = localStorage.getItem('emailchef_account_id');

    if (!localStorageValue || localStorageValue === '') {
      return environment.emailchefAuthkey;
    }
    return localStorageValue;
  }

  public get currentUserValue(): Auth {
    return this.currentUserSubject.value;
  }

  isLogged(): boolean {
    const user: Auth = this.currentUserValue;
    return !(user === null || user.Authkey === null);

  }

}
