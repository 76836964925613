import {inject, Injectable} from '@angular/core';
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Api} from "../_interfaces/api";
import {
  ContactFieldComporatorList,
  PlatformCampaign,
  PlatformContactField,
  PlatformLink,
  PlatformList,
  PlatformMessage,
  PlatformSegment,
  Sender
} from "../_interfaces/platform";
import {filter, map, Observable, of, shareReplay, switchMap, toArray} from "rxjs";
import {catchError} from "rxjs/operators";
import {skipModalError} from "@app/shared/_models/auth";

@Injectable({
  providedIn: 'root'
})
export class EmailchefService {
  private readonly apiPrefix = `${environment.apiUrl}/emailchef`;

  private _comparators$: Observable<Api.Response<ContactFieldComporatorList>>;
  private http = inject(HttpClient);

  getAccountInfo() {
    return this
      .http
      .get<Api.Response<{ account_id: number, lang: string }>>(
        `${this.apiPrefix}/accountinfo`
      ).pipe(
        shareReplay()
      );
  }

  lists() {
    return this
      .http
      .get<Api.Response<any>>(
        `${this.apiPrefix}/list`
      );
  }

  campaigns$(
    flows: boolean = false
  ) {
    return this
      .http
      .get<Api.Response<PlatformMessage[]>>(
        `${this.apiPrefix}/campaign`, {
          params: {
            campaign_type: flows ? 'flow' : 'normal'
          }
        }
      );
  }

  flowEmails$() {
    return this.campaigns$(true);
  }

  getCampaign(
    campaignId : number
  ) {
    return this
      .http
      .get<Api.Response<PlatformCampaign>>(
        `${this.apiPrefix}/campaign/${campaignId}`
      );
  }

  validateCampaignName(name: string){
    return this
      .http
      .post<Api.Response<{ reason: string  }>>(
        `${this.apiPrefix}/message/validate_name`, {
          name
        }, {
          context: skipModalError([400])
        }
      ).pipe(
        catchError(e => of({success: false}))
      );
  }

  updateCampaign(
    messageId: number | null,
    data: Partial<PlatformCampaign>
  ) {
    return this
      .http
      .request<Api.Response<PlatformCampaign>>(
        messageId ? 'PUT' : 'POST',
        `${this.apiPrefix}/campaign${messageId ? `/${messageId}` : ''}`,
        {body:data}
      );
  }

  cloneCampaign(
    messageId: number,
    flow_id: number
  ){
    return this
      .http
      .post<Api.Response<PlatformCampaign>>(
        `${this.apiPrefix}/campaign/${messageId}/clone`,
        {
          flow_id
        }
      );
  }

  autoresponders$() {
    return this
      .http
      .get<Api.Response<PlatformMessage[]>>(
        `${this.apiPrefix}/autoresponder`
      );
  }

  lists$() {
    return this
      .http
      .get<Api.Response<PlatformList[]>>(
        `${this.apiPrefix}/list`
      );
  }

  comparators$(){
    if (!this._comparators$) {
      this._comparators$ = this
        .http
        .get<Api.Response<ContactFieldComporatorList>>(
          `${this.apiPrefix}/comparators`
        ).pipe(
          shareReplay(1)
        );
    }
    return this._comparators$
  }

  links(
    messageId: number
  ) {
    return this
      .http
      .get<Api.Response<PlatformLink[]>>(
        `${this.apiPrefix}/message/${messageId}/link`
      ).pipe(
        map<Api.Response<PlatformLink[]>, Api.Response<PlatformLink[]>>(res => ({
          ...res,
          ...res?.data && {
            data: res.data.filter(link => !link.url.startsWith('#'))
          }
        }))
      );
  }

  segments$(listId: number) {
    return this
      .http
      .get<Api.Response<PlatformSegment[]>>(
        `${this.apiPrefix}/segment`
      )
      .pipe(
        map(res => res?.data),
        switchMap(segments => segments),
        filter(segment => segment.list_id === listId),
        toArray()
      );
  }

  contactFields(listId: number) {
    return this
      .http
      .get<Api.Response<PlatformContactField[]>>(
        `${this.apiPrefix}/list/${listId}/contactfields`
      );
  }

  senders() {
    return this
      .http
      .get<Api.Response<Sender[]>>(
        `${this.apiPrefix}/sender`
      );
  }


}
