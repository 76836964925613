import {RouterModule, Routes} from '@angular/router';


const appRoutes: Routes = [
  {path: '', loadChildren: () => import('./features/gallery/gallery.routes').then(m => m.galleryRoutes)},
  {path: 'editor', loadChildren: () => import('./features/editor/builder.routes').then(m => m.builderRoutes)},
  {path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(appRoutes, {});
