import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MockService {

  private mocked: any = {};

  getData(path: string) {

    if (!(path in this.mocked)) {
      try {
        this.mocked[path] = require(`../../../config/${path}.json`);
      } catch (e) {
        console.error(`Error Mocking: `, e);
        return {};
      }
    }
    return this.mocked[path];
  }
}
