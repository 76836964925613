export const replaceText = (
  text: string,
  mapKeys: { [key: string]: string | number }
) => {

  let _text = text;

  Object.keys(mapKeys).forEach(mapKey => {
    _text = _text.replaceAll('{' + mapKey + '}', (mapKeys[mapKey] ?? '').toString());
  });

  return _text;

}
