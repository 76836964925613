import {Injectable} from '@angular/core';
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HotjarService {

  private hasHotJar = false;

  identify(
    account_id: number,
    data: any
  ){
    if (this.hasHotJar && 'hj' in window) {
      (window.hj as any)('identify', account_id, data);
    }
    console.log('o qui');
  }

  initHotjar() {
      (function (h: any, o, t, j, a, r) {
        h.hj = h.hj || function () {
          (h.hj.q = h.hj.q || []).push(arguments)
        };
        h._hjSettings = {hjid: environment.hotjar.id, hjsv: environment.hotjar.sv};
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      if (!environment.production){
        console.log('Hotjar loaded...');
      }

      this.hasHotJar = true;

  }
}
