export const environment = {
  production: false,
  apiUrl: 'https://api.dev.flows.emailchef.com/index.php',
  //apiUrl: 'https://andreadb.dev.flows.emailchef.com/index.php',
  emailchefEndpointUrl: "https://alessandro.dev.emailchef.com",
  emailchefAuthkey: null,
  showDebugInfo: false,
  canvasRightClick: false,
  hotjar: null,
  type: 'dev'
};
